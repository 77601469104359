import * as React from "react"
import * as styles from "../styles/home.module.scss"
import Template from "../components/ui/Template"
import { Button } from "@material-ui/core"
import { Helmet } from "react-helmet"
import { Block } from "@material-ui/icons"
import { Link } from "gatsby"

class IndexPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    return <Template currentPage="group" lightnessTheme="dark">
      <Helmet>
        <title>Daya Selaras Group - Cipta Kelola Selaras</title>
      </Helmet>
      <div className={styles.jumbotron} style={{backgroundImage: "url('/static/images/photos/cks.jpg')"}}>
        <div className={styles.fence}>
          <div className={styles.cksSlide}>
            {/* <img src="/static/images/cks-logo.png" className={styles.logo} /> */}
            <div className={styles.title}>
              CIPTA KELOLA SELARAS
            </div>
            <div className={styles.info}>
              An industrial logistics company providing value-added delivery and sustainable management in Indonesia.
            </div>
            <Link to="/cks#services" className={styles.buttonLink}><Button className={styles.button} color="primary" variant="contained">READ MORE</Button></Link>
          </div>
        </div>
      </div>
      <div className={`${styles.jumbotron} ${styles.jumbotron__light}`} style={{backgroundImage: "none", backgroundColor: "#d9d9d9"}} id="services">
        <div className={`${styles.fence} ${styles.fence__row}`}>
          <div className={styles.cksSlide}>
            <div className={styles.title}>
              OUR <br />SERVICES
            </div>
          </div>
          <img src="/static/images/cks-detail.png" style={{margin: "32px 0", width: "100%", maxWidth: "900px"}} />
        </div>
      </div>
      <div className={styles.jumbotron} style={{backgroundImage: `url('/static/images/photos/cks-2.jpg')`}}>
        <div className={`${styles.fence} ${styles.fence__row}`} style={{alignItems: "flex-center"}}>
          <div className={styles.cksSlide}>
            <div className={styles.info} style={{maxWidth: "700px"}}>
              <ol>
                <li>Providing on-time and safe deliveries for various products</li>
                <li>Building a network of connection and trust</li>
                <li>Providing logistical optimization and environmental solution to waste management</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </Template>
  }
}

export default IndexPage
